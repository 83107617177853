<template>
  <page-content>
    <page-title heading="รายงานสถานะแผนการสอน">
      <template v-slot:right>
        <b-button variant="danger" @click="showExportOption = true">
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงาน</span>
        </b-button>

        <!-- <download-excel
          class="btn btn-danger cursor-pointer"
          :fields="export_fields"
          :fetch="fetchExport"
        >
          <b-spinner v-if="isExporting" label="exporting"></b-spinner>
          <span v-else>ดาวน์โหลดรายงาน</span>
        </download-excel> -->
      </template>
    </page-title>

    <b-row>
      <b-col cols="12">
        <filter-fiscal-year-term v-model="formFiscalYearTerm">
        </filter-fiscal-year-term>
      </b-col>

      <b-col cols="12" class="mt-2">
        <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master>

        <!-- <filter-master
          v-model="formfilter"
          :hiddenInput="{ school: true, schoolClass: true }"
        >
        </filter-master> -->
      </b-col>

      <b-col cols="12" class="mt-2">
        <filter-date-between v-model="filterDateBetween">
          <template #before>
            <b-col cols="12" lg="4" class="px-2">
              <label class="font-weight-bold ft-s-14" for="isPlan"
                >ผลการบันทึกการสอน</label
              >

              <v-select
                id="isPlan"
                v-model="isPlan"
                :clearable="isPlan !== defaultValue"
                :options="planStatuses"
                @input="onInput($event, 'isPlan')"
              ></v-select>
            </b-col>
          </template>
        </filter-date-between>
      </b-col>

      <b-col cols="12" class="mt-4 mt-sm-2">
        <div class="d-flex flex-row flex-wrap align-items-end">
          <b-col cols="6" lg="8" class="px-2">
            <b-form-group class="mb-0">
              <b-input-group class="input-group-seamless">
                <b-form-input
                  placeholder="ค้นหารายชื่อครูหรือรายชื่อโรงเรียน"
                  v-model="keyword"
                  @keyup.enter="onKeywordSearched"
                ></b-form-input>

                <b-input-group-text slot="prepend">
                  <font-awesome-icon class="mx-auto" icon="search" />
                </b-input-group-text>

                <b-input-group-text v-show="keyword.length" slot="append">
                  <a href="javascript:void(0)" @click="clearKeyword">
                    <font-awesome-icon class="mx-auto" icon="window-close" />
                  </a>
                </b-input-group-text>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="2" class="px-2">
            <b-button
              variant="primary"
              block
              size="sm"
              @click="onKeywordSearched"
              >ค้นหา</b-button
            >
          </b-col>
        </div>
      </b-col>
    </b-row>

    <report-progress-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="TableParams"
      action-label=""
      :show-action="false"
      :see-detail="true"
      :show-delete="false"
      @seeDetail="onSeeDetail"
      @delete="onDelete"
    />

    <create-or-update-class
      :edit-data.sync="editData"
      disabled
      @create:success="onCreateSuccess"
      v-model="showModal"
    ></create-or-update-class>

    <option-modal
      title="เลือกรูปแบบการนำออกข้อมูล"
      name="exportOverviewOption"
      :options="exportOptions"
      v-model="showExportOption"
      @selected:type="exportReport"
    >
    </option-modal>
  </page-content>
</template>

<script>
import reportMixin from "../../mixins/reportMixin";
import { saveAs } from "file-saver";
import XLSX from "xlsx";
import { sortBy, groupBy } from "lodash";
import vSelect from "vue-select";
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateClass from "../../components/modal/CreateOrUpdateClass";
import ReportProgressTable from "../../components/table/ReportProgress";
import FilterMaster from "../../components/form/FilterMaster";
import FilterFiscalYearTerm from "../../components/form/FilterFiscalYearTerm";
import FilterDateBetween from "../../components/form/FilterDateBetween";
import OptionModal from "../../components/modal/Option";
// import DeleteUser from "../../components/modal/DeleteUser";
import {
  Auth,
  TeachingPlan,
  DirectorDare,
  StudentDare,
  TeacherDare,
  // Transection,
} from "../../models";

const defaultValue = "ทั้งหมด";

export default {
  mixins: [reportMixin],

  components: {
    vSelect,
    PageContent,
    PageTitle,
    ReportProgressTable,
    FilterMaster,
    FilterFiscalYearTerm,
    // DownloadExcel,
    CreateOrUpdateClass,
    FilterDateBetween,
    OptionModal,
  },

  data() {
    return {
      defaultValue,
      keyword: "",
      // terms: ["1", "2"],
      planStatuses: [defaultValue, "สำเร็จ", "ไม่สำเร็จ"],
      isPlan: defaultValue,
      // fiscalYearFrom: "",
      // termFrom: "",
      // fiscalYearTo: "",
      // termTo: "",
      formfilter: {},
      filterDateBetween: {
        startDate: null,
        endDate: null,
      },
      formFiscalYearTerm: {
        fiscalYearFrom: defaultValue,
        termFrom: defaultValue,
        fiscalYearTo: defaultValue,
        termTo: defaultValue,
      },
      deleteData: {},
      editData: {},
      isExporting: false,
      showModal: false,
      showModalDelete: false,
      exportFields: {
        หน่วย: "headquarter",
        จังหวัด: "division",
        สถานี: "station",
        "ยศ ชื่อ-นามสกุล": "user_name",
        โรงเรียน: "school",
        // อำเภอ: "amphur",
        // จังหวัด: "province",
        ห้องเรียน: "classRoom",
        // ปีการศึกษา: "yearTerm",
        จำนวนนักเรียน: "actual_student",
        ผลการบันทึกการสอน: "isPlan",
        // วันที่สร้าง: "created_at",
        // วันที่อัพเดตล่าสุด: "updated_at",
      },
      // sortCol: "user_name",
      colKey: "ยศ ชื่อ-นามสกุล",
      mergedCol: [],

      showExportOption: false,

      exportOptions: [
        { value: "onePage", label: "นำออกรายงานรวมหน้าเดียว" },
        { value: "multiplePages", label: "นำออกรายงานแยกหลายหน้า" },
      ],
    };
  },

  // watch: {
  //   formfilter: {
  //     deep: true,
  //     handler: "onFormFilterChanged",
  //   },
  // },

  computed: {
    authUser() {
      return Auth.user();
    },

    fields() {
      const fields = [
        // { key: "order", label: "ลำดับ" },
        { key: "station", label: "สถานี", sortable: true },
        { key: "user_name", label: "ยศ ชื่อ-นามสกุล", sortable: true },
        { key: "school", label: "โรงเรียน", sortable: true },
        // { key: "amphur", label: "อำเภอ" },
        // { key: "province", label: "จังหวัด" },
        { key: "yearTerm", label: "ปีการศึกษา", sortable: true },
        { key: "classRoom", label: "ห้องเรียน", sortable: true },
        { key: "actual_student", label: "จำนวนนักเรียน", sortable: true },
        { key: "isPlan", label: "ผลการบันทึกการสอน", sortable: true },
        { key: "seeDetail", label: "ข้อมูล", class: "text-center" },
      ];

      return fields;
    },

    TableParams() {
      const { formfilter, filterDateBetween: dateParams } = this;

      let isPlan = null;

      if (this.isPlan && this.isPlan !== defaultValue) {
        if (this.isPlan === "ไม่สำเร็จ") {
          isPlan = "ไม่ทำแผนการสอน";
        }

        if (this.isPlan === "สำเร็จ") {
          isPlan = "ทำแผนการสอน";
        }
      }
      return {
        ...formfilter,
        ...this.getFilterTermYear(),
        ...dateParams,
        isPlan,
      };
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    getFilterTermYear() {
      let params = {};

      if (
        this.formFiscalYearTerm.fiscalYearFrom &&
        this.formFiscalYearTerm.fiscalYearFrom !== defaultValue
      ) {
        this.$set(
          params,
          "fiscalYearFrom",
          this.formFiscalYearTerm.fiscalYearFrom
        );
      }

      if (
        this.formFiscalYearTerm.termFrom &&
        this.formFiscalYearTerm.termFrom !== defaultValue
      ) {
        this.$set(params, "termFrom", this.formFiscalYearTerm.termFrom);
      }

      if (
        this.formFiscalYearTerm.fiscalYearTo &&
        this.formFiscalYearTerm.fiscalYearTo !== defaultValue
      ) {
        this.$set(params, "fiscalYearTo", this.formFiscalYearTerm.fiscalYearTo);
      }

      if (
        this.formFiscalYearTerm.termTo &&
        this.formFiscalYearTerm.termTo !== defaultValue
      ) {
        this.$set(params, "termTo", this.formFiscalYearTerm.termTo);
      }

      return params;
    },

    async fetchExport() {
      let data = [];

      this.isExporting = true;

      try {
        data = await this.$refs.table.fetch({ downloadAll: true });
      } catch (error) {
        this.$toast.error("ไม่สามารถดึงข้อมูลรายงานได้ กรุณาลองใหม่ภายหลัง");
      } finally {
        this.isExporting = false;
      }

      return data;
    },

    exportByType(type, data) {
      let wb;

      if (type === "onePage") {
        wb = this.exportOnePage(data);
      }

      if (type === "multiplePages") {
        wb = this.exportMultiplePage(data);
      }

      return wb;
    },

    async exportReport(type) {
      let data = await this.fetchExport();

      // let exportFields = { ...this.exportFields };

      // if (this.formfilter.mStationId) {
      //   this.$delete(exportFields, "สถานี");
      // }

      // let header = Object.keys(exportFields);

      // const wb = XLSX.utils.book_new();

      // let sheetHeader = ["ลำดับ", ...header];

      if (data && data.length) {
        // let groupedData = {};

        // if (this.formfilter.mStationId) {
        //   groupedData = groupBy(data, "station");
        // } else {
        //   groupedData = groupBy(data, "division");
        // }

        //   for (const groupName in groupedData) {
        //     if (Object.hasOwnProperty.call(groupedData, groupName)) {
        //       let groupRecords = groupedData[groupName];

        //       if (groupRecords && groupRecords.length) {
        //         groupRecords = groupRecords.map((record, index) => {
        //           const res = {
        //             ลำดับ: index + 1,
        //           };

        //           header.forEach((col) => {
        //             res[col] = record[exportFields[col]];
        //           });

        //           return res;
        //         });
        //       }

        //       let ws = XLSX.utils.json_to_sheet(groupRecords, {
        //         header: sheetHeader,
        //       });

        //       ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
        //         orderCol: "A",
        //       });

        //       ws = this.$_reportMixin_setWidthColumn(
        //         ws,
        //         sheetHeader,
        //         groupRecords
        //       );

        //       // console.log(ws);

        //       XLSX.utils.book_append_sheet(wb, ws, groupName);
        //     }
        //   }

        // data = data.map((record, index) => {
        //   const res = {
        //     ลำดับ: index + 1,
        //   };

        //   header.forEach((col) => {
        //     res[col] = record[exportFields[col]];
        //   });

        //   return res;
        // });

        // let ws = XLSX.utils.json_to_sheet(data, {
        //   header: sheetHeader,
        // });

        // ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
        //   orderCol: "A",
        // });

        // ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, data);

        // XLSX.utils.book_append_sheet(wb, ws, "สถานะแผนการสอน");
        const wb = this.exportByType(type, data);

        /* bookType can be any supported output type */
        const wopts = { bookType: "xlsx", bookSST: false, type: "array" };

        const wbout = XLSX.write(wb, wopts);

        /* the saveAs call downloads a file on the local machine */
        saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "รายงานสถานะแผนการสอน.xlsx"
        );
      }
    },

    exportOnePage(data) {
      let exportFields = { ...this.exportFields };

      if (this.formfilter.mStationId) {
        this.$delete(exportFields, "สถานี");
      }

      let header = Object.keys(exportFields);

      const wb = XLSX.utils.book_new();

      let sheetHeader = ["ลำดับ", ...header];

      // let groupedData = {};

      // if (this.formfilter.mStationId) {
      //   groupedData = groupBy(data, "station");
      // } else {
      //   groupedData = groupBy(data, "division");
      // }

      //   for (const groupName in groupedData) {
      //     if (Object.hasOwnProperty.call(groupedData, groupName)) {
      //       let groupRecords = groupedData[groupName];

      //       if (groupRecords && groupRecords.length) {
      //         groupRecords = groupRecords.map((record, index) => {
      //           const res = {
      //             ลำดับ: index + 1,
      //           };

      //           header.forEach((col) => {
      //             res[col] = record[exportFields[col]];
      //           });

      //           return res;
      //         });
      //       }

      //       let ws = XLSX.utils.json_to_sheet(groupRecords, {
      //         header: sheetHeader,
      //       });

      //       ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
      //         orderCol: "A",
      //       });

      //       ws = this.$_reportMixin_setWidthColumn(
      //         ws,
      //         sheetHeader,
      //         groupRecords
      //       );

      //       // console.log(ws);

      //       XLSX.utils.book_append_sheet(wb, ws, groupName);
      //     }
      //   }

      data = data.map((record, index) => {
        const res = {
          ลำดับ: index + 1,
        };

        header.forEach((col) => {
          res[col] = record[exportFields[col]];
        });

        return res;
      });

      let ws = XLSX.utils.json_to_sheet(data, {
        header: sheetHeader,
      });

      ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
        orderCol: "A",
      });

      ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, data);

      XLSX.utils.book_append_sheet(wb, ws, "สถานะแผนการสอน");

      return wb;
    },

    exportMultiplePage(data) {
      let exportFields = { ...this.exportFields };

      // if (this.formfilter.mStationId) {
      //   this.$delete(exportFields, "สถานี");
      // }

      let header = Object.keys(exportFields);

      const wb = XLSX.utils.book_new();

      let sheetHeader = ["ลำดับ", ...header];

      let groupedData = {};

      if (this.formfilter.mStationId) {
        groupedData = groupBy(data, "station");
      } else {
        groupedData = groupBy(data, "division");
      }

      for (const groupName in groupedData) {
        if (Object.hasOwnProperty.call(groupedData, groupName)) {
          let groupRecords = groupedData[groupName];

          if (groupRecords && groupRecords.length) {
            groupRecords = groupRecords.map((record, index) => {
              const res = {
                ลำดับ: index + 1,
              };

              header.forEach((col) => {
                res[col] = record[exportFields[col]];
              });

              return res;
            });
          }

          let ws = XLSX.utils.json_to_sheet(groupRecords, {
            header: sheetHeader,
          });

          ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
            orderCol: "A",
          });

          ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, groupRecords);

          // console.log(ws);

          XLSX.utils.book_append_sheet(wb, ws, groupName);
        }
      }

      // data = data.map((record, index) => {
      //   const res = {
      //     ลำดับ: index + 1,
      //   };

      //   header.forEach((col) => {
      //     res[col] = record[exportFields[col]];
      //   });

      //   return res;
      // });

      // let ws = XLSX.utils.json_to_sheet(data, {
      //   header: sheetHeader,
      // });

      // ws = this.$_reportMixin_mergeCellBy(ws, this.colKey, {
      //   orderCol: "A",
      // });

      // ws = this.$_reportMixin_setWidthColumn(ws, sheetHeader, data);

      // XLSX.utils.book_append_sheet(wb, ws, "สถานะแผนการสอน");

      return wb;
    },

    async onSeeDetail(item) {
      this.editData = { ...item };

      if (item.m_station) {
        this.$set(this.editData, "mDivisionId", item.m_station.mDivisionId);

        if (item.m_station.m_division) {
          this.$set(
            this.editData,
            "mHeadquarterId",
            item.m_station.m_division.mHeadquarterId
          );
        }
      }

      if (item.m_school) {
        this.$set(this.editData, "mProvinceId", item.m_school.mProvinceId);
        this.$set(this.editData, "mAmphurId", item.m_school.mAmphurId);
      }

      const [
        teachingPlanPromise,
        directorDarePromise,
        studentDarePromise,
        teacherDarePromise,
      ] = await Promise.all([
        TeachingPlan.api().findAll({ teachingDareId: item.id }),
        DirectorDare.api().findAll({ teachingDareId: item.id }),
        StudentDare.api().findAll({ teachingDareId: item.id }),
        TeacherDare.api().findAll({ teachingDareId: item.id }),
      ]);

      const { data: teachingPlanData = [] } = teachingPlanPromise.response.data;

      if (teachingPlanData.length) {
        this.$set(
          this.editData,
          "teaching_plans",
          sortBy(teachingPlanData, "createdAt").map(
            ({
              lesson_number,
              lesson_name,
              date,
              time,
              content,
              note,
              teaching_actual_imgs = [],
            }) => ({
              lesson_number,
              lesson_name,
              date,
              time,
              content,
              note,
              teaching_actual_imgs,
            })
          )
        );
      }

      const { data: directorDareData = [] } = directorDarePromise.response.data;

      if (directorDareData.length) {
        this.$set(
          this.editData,
          "director_dares",
          sortBy(directorDareData, "createdAt").map(
            ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            }) => ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            })
          )
        );
      }

      const { data: studentDareData = [] } = studentDarePromise.response.data;

      if (studentDareData.length) {
        this.$set(
          this.editData,
          "student_dares",
          sortBy(studentDareData, "createdAt").map(
            ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            }) => ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            })
          )
        );
      }

      const { data: teacherDareData = [] } = teacherDarePromise.response.data;

      if (teacherDareData.length) {
        this.$set(
          this.editData,
          "teacher_dares",
          sortBy(teacherDareData, "createdAt").map(
            ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            }) => ({
              first_name,
              last_name,
              id_card,
              phone_number,
              email,
              mPrefixId,
              mCardtypeId,
            })
          )
        );
      }

      this.showModal = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },

    onInput(value, dataName) {
      if (!value) {
        this.$set(this, dataName, this.defaultValue);
      }
    },
  },
};
</script>
